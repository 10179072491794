import axios from 'axios';
import { appConfig } from '../config/app';
import { NavigateFunction } from 'react-router-dom';

const api = axios.create({
  baseURL: appConfig.apiConfig.baseURL,
});

export const postData = async (url: string, data: any, token: string, navigate: NavigateFunction, headers: any = {}) => {
  try {
    const baseURL = `${appConfig.apiConfig.baseURL}`;
    const mURL = url.startsWith('/') ? url : `/${url}`;
    const fullURL = baseURL + mURL;

    headers = { ...headers, Authorization: `Bearer ${token}` };

    return await api.post(fullURL, data, { headers });
  } catch (error) {
    console.error('Error en la solicitud POST:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      navigate('/login');
    }
    throw error;
  }
};

export const getData = async (url: string, navigate: NavigateFunction, token: string, headers: any = {}) => {
  try {
    const baseURL =  `${appConfig.apiConfig.baseURL}`;
    const mURL = url.startsWith('/') ? url : `/${url}`;
    const fullURL = baseURL + mURL;

    headers = { ...headers, Authorization: `Bearer ${token}` };

    return await api.get(fullURL, { headers });
  } catch (error) {
    console.error('Error en la solicitud GET:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      navigate('/login');
    }
    throw error;
  }
};

export const patchData = async (url: string, navigate: NavigateFunction, token: string, headers: any = {}) => {
  try {
    const baseURL =  `${appConfig.apiConfig.baseURL}`;
    const mURL = url.startsWith('/') ? url : `/${url}`;
    const fullURL = baseURL + mURL;

    headers = { ...headers, Authorization: `Bearer ${token}` };

    return await api.patch(fullURL, headers);
  } catch (error) {
    console.error('Error en la solicitud PATCH:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      navigate('/login');
    }
    throw error;
  }
};

export const putData = async (url: string, data: any, navigate: NavigateFunction, token: string, headers: any = {}) => {
  try {
    const baseURL = `${appConfig.apiConfig.baseURL}`;
    const mURL = url.startsWith('/') ? url : `/${url}`;
    const fullURL = baseURL + mURL;

    headers = { ...headers, Authorization: `Bearer ${token}` };

    return await api.put(fullURL, data, { headers });
  } catch (error) {
    console.error('Error en la solicitud PUT:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      navigate('/login');
    }
    throw error;
  }
};

export const deleteData = async (url: string, navigate: NavigateFunction, token: string, headers: any = {}) => {
  try {
    const baseURL = `${appConfig.apiConfig.baseURL}`;
    const mURL = url.startsWith('/') ? url : `/${url}`;
    const fullURL = baseURL + mURL;

    headers = { ...headers, Authorization: `Bearer ${token}` };

    return await api.delete(fullURL, { headers });
  } catch (error) {
    console.error('Error en la solicitud DELETE:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      navigate('/login');
    }
    throw error;
  }
};