import '../../assets/scss/components/_filters.scss';
import React, { useState, useEffect } from 'react';
import Input from "../Input";
import Button from "../Button";
import Select from "../Select";
import CloseIcon from '../../assets/icons/close.svg';
import {InputType} from "../../shared/types";

type CampoElemento =
  | {
  name: string;
  label?: string;
  placeholder: string;
  typeElem: string;
  type?: InputType;
  flex?: string;
}
  | {
  name: string;
  label?: string;
  placeholder: string;
  typeElem: string;
  options?: { text: string; value: string }[];
};

interface FiltersComponentProps<T> {
  onHide: () => void;
  datos: T;
  camposFormulario: CampoElemento[];
  onAplicar: (filtros: T) => void;
}

function FiltersComponent<T>({ onHide, datos: datosProp, camposFormulario, onAplicar }: FiltersComponentProps<T>) {
  const [datos, setDatos] = useState<T>(datosProp);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAplicar(datos);
  };

  const handleClose = () => {
    onHide();
  };

  const handleChange = (campo: keyof T, valor: string) => {
    setDatos((prevDatos) => ({ ...prevDatos, [campo]: valor }));
  };

  return (
    <div className="filters-component">
      <div className="modal-title">
        <h3>Filtros</h3>
        <img src={CloseIcon} alt="Cerrar" onClick={handleClose} />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="fields-container">
          {camposFormulario.map((elemento) => (
            <div>
              <React.Fragment key={elemento.name}>
                {elemento.typeElem === 'input' && (
                  <Input
                    key={elemento.name}
                    label={(elemento as { label?: string }).label || ''}
                    placeholder={elemento.placeholder}
                    value={String(datos[elemento.name as keyof T]) !== 'undefined' ? String(datos[elemento.name as keyof T]) : ''}
                    onChange={(value) => handleChange(elemento.name as keyof T, value)}
                  />
                )}

                {elemento.typeElem === 'select' && (
                  <Select
                      label={(elemento as { label?: string }).label || ''}
                      placeholder={elemento.placeholder}
                      options={(elemento as { options?: { text: string; value: string }[] }).options || []}
                      value={String(datos[elemento.name as keyof T]) !== 'undefined' ? String(datos[elemento.name as keyof T]) : ''}
                      onChange={(e) => handleChange(elemento.name as keyof T, e.target.value)}
                  />
                )}
              </React.Fragment>
            </div>
          ))}
        </div>

        <div className="buttons-container">
          <Button color="outline" onClick={handleClose}>Cancelar</Button>
          <Button color="primary" type="submit">Aplicar</Button>
        </div>
      </form>
    </div>
  );
}

export default FiltersComponent;