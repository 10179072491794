import React, { useState } from 'react';
import '../../assets/scss/pages/_communications.scss';
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import CommunicationsIcon from '../../assets/icons/communications-grey.svg';
import goBackIcon from '../../assets/icons/arrow-left.svg';
import ConsultasTab from './Consultas';
import EnviosTab from './Envios';
import { useNavigate, useLocation } from 'react-router-dom';

function ComunicacionesPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: string } | undefined)?.from || 'atrás';
  const [tabSeleccionada, setTabSeleccionada] = useState('tab1');

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="communications-page">
      <Navbar />

      <div className="main-content">

        <Button className="backBtn" color="transparent" onClick={handleGoBack}>
          <img src={goBackIcon} alt="Volver atrás" />
          Regresar a {from}
        </Button>

        <div className="title">
          <img src={CommunicationsIcon} alt="Comunicaciones" />
          <h2>Comunicaciones</h2>
        </div>

        <div className="tabs-container">
          {/* Botones de las pestañas */}
          <button onClick={() => setTabSeleccionada('tab1')} className={tabSeleccionada === 'tab1' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
            Envíos
          </button>
          <button onClick={() => setTabSeleccionada('tab2')} className={tabSeleccionada === 'tab2' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
            Consultas
          </button>
        </div>

        <div className="tabs-content">
          {/* Contenido de la pestaña seleccionada */}
          {tabSeleccionada === 'tab1' ?  <EnviosTab /> : <ConsultasTab />}
        </div>
      </div>
    </div>
  );
}

export default ComunicacionesPage;