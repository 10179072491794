import React, { useState, useMemo, useEffect } from 'react';
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import FiltersComponent from "../../../components/Filters";
import AddIcon from '../../../assets/icons/plus-circle.svg';
import CommunicationsIcon from '../../../assets/icons/annotation-dots.svg';
import FilterIcon from '../../../assets/icons/filter-lines.svg';
import SearchIcon from '../../../assets/icons/search.svg';
import DataTable, { TableColumn } from 'react-data-table-component';
import CustomPagination from "../../../components/Pagination";
import MaterialIconMenu, { MaterialIconMenuProps } from "../../../components/MaterialIconMenu";
import { useNavigate, NavigateFunction, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { getData, postData } from '../../../API/index';
import { AxiosResponse } from "axios";
import { AlertModalType, getButtonColor } from "../../../shared/types";
import { Inventario } from "../../../API/interfaces";
import AlertModal from "../../../components/AlertModal/index";

const InventarioTab = (): JSX.Element => {

  const elemPerPage = 15;
  const totalElemLimit = 1000;

  const urlGetDatos = '/api/iho/inventario';
  const [inventario, setInventario] = useState<Inventario[]>(() => {
    const storedData = localStorage.getItem('inventario');
    return storedData ? JSON.parse(storedData) : [];
  });
  const [selectedRows, setSelectedRows] = useState<Inventario[]>();
  const [searchText, setSearchText] = useState('');
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(() => {
    const storedTotalRows = localStorage.getItem('totalRows');
    return storedTotalRows ? parseInt(storedTotalRows, 10) : 0;
  });
  const [modalConfig, setModalConfig] = useState<{ title: string; type: AlertModalType, description?: string, onSubmit?: () => void, onClose?: () => void } | null>(null);
  const location = useLocation();
  const navigate = useNavigate() as NavigateFunction;
  const { token } = useAuth();
  const urlEnviarMINTDatos = '/api/carga/iho/inventario/BBDD';
  const initialHistoryLength = window.history.length; // Guardamos el número de navegación porque al volver a atrás,
  // puede que tengamos la marca de Home sin venir directos de la home, sino que se haya recargado esa llamada.

  const addSelectedToRow = (row: Inventario): void => {
    document.getElementById(`row-${row.id}`)?.classList.add('active');
  };

  const removeSelectedToRow = (row: Inventario) => {
    document.getElementById(`row-${row.id}`)?.classList.remove('active');
  };

  const handleShowMap = (inventario: Inventario) => {
    let elements: Inventario[] = [ inventario ];
    if(selectedRows && selectedRows.length > 0) {
      elements = selectedRows;
    }
    const state = { elements, tipo: 'Inventario', from: 'InventarioListado' };
    window.history.pushState(state, '', ''); // Hay que hacer este push state porque sino al ir al mapa desde el listado
                                             // y dar a atrás, sale que venimos desde la home, ya que es lo último en el historial
    navigate('/mapa-fuentes', { state });
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleSentToMINT = async (row: Inventario) => {
    let elements: string[] = [ String(row.id) ];
    if(selectedRows && selectedRows.length > 0) {
      elements = selectedRows.flatMap((selectedRow) => String(selectedRow.id));
    }

    const formData = {
      ids: elements
    };

    const response = await postData(`${urlEnviarMINTDatos}`, formData, token, navigate);
    if(response.status === 200) {
      const config = {
        title: 'Elemento cargado en gestión de envíos',
        description: 'El elemento se ha puesto en cargas de envíos',
        type: 'ok' as AlertModalType,
        onSubmit: handleCloseModal,
        onClose: handleCloseModal
      };
      setModalConfig(config);
    }
  };

  const handleCloseModal = () => {
    setModalConfig(null);
  };

  // Filtrar inventario según el texto de búsqueda
  const filteredInventario = useMemo(() => {
    return inventario.filter(inventario =>
      Object.values(inventario).some(value =>
        value && value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [inventario, searchText]);

  const columns: TableColumn<Inventario>[] = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row.id,
        sortable: true
      },
      {
        name: 'Id Inventario',
        selector: (row) => row.idInventario,
        sortable: true
      },
      {
        name: 'Barrio',
        selector: (row) => row.barrio,
        sortable: true
      },
      {
        name: 'Distrito',
        selector: (row) => row.distrito,
        sortable: true
      },
      {
        name: 'Jerarquía Clasificación',
        selector: (row) => row.jerarquiaClasificacion,
        sortable: true
      },
      {
        name: 'Nombre vía',
        selector: (row) => row.nombreVia,
        sortable: true
      },
      {
        name: 'Número vía',
        selector: (row) => row.numeroVia,
        sortable: true
      },
      {
        name: 'Estado General',
        selector: (row) => row.estado_general.label, // Selector que devuelve un tipo primitivo
        sortable: true,
        cell: (row) => (
          <Button className="estado-button" color={getButtonColor(row.estado_general.nombre)}>
            {row.estado_general.label}
          </Button>
        ),
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Inventario> = {
            row,
            size: 'medium',
            rows: selectedRows && selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              { label: 'Ver en mapa', onClick: row => handleShowMap(row) },
              {
                label: 'Editar',
                onClick: row => navigate(`/fuentes-ornamentales/inventario/editar/${row.id}`, { state: { from: 'Inventario' } } ),
                onlyApplyToOne: true,
              },
              { label: `Enviar a MiNT`, onClick: row => handleSentToMINT(row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  /* Filtros */
  type DatosFiltrosType = {
    id: string,
    idInventario: string;
    idInventarioInterno: string;
    idInventarioExterno: string;
    contrato: string;
    lote: string;
    barrio: string;
    distrito: string;
    nombreVia: string;
    numeroVia: string;
    claseFuncionGIS: string;
    jerarquiaClasificacion: string;
    estado: string;
  };

  const [filtros, setFiltros] = useState<DatosFiltrosType>({
    id: '',
    idInventario: '',
    idInventarioInterno: '',
    idInventarioExterno: '',
    contrato: '',
    lote: '',
    barrio: '',
    distrito: '',
    nombreVia: '',
    numeroVia: '',
    claseFuncionGIS: '',
    jerarquiaClasificacion: '',
    estado: ''
  });

  const camposFiltros = [
    { name: 'id', label: 'ID', placeholder: 'ID', typeElem: 'input', type: 'text' },
    { name: 'idInventario', label: 'idInventario', placeholder: 'idInventario', typeElem: 'input', type: 'text' },
    { name: 'idInventarioInterno', label: 'idInventarioInterno', placeholder: 'idInventarioInterno', typeElem: 'input', type: 'text' },
    { name: 'idInventarioExterno', label: 'idInventarioExterno', placeholder: 'idInventarioExterno', typeElem: 'input', type: 'text' },
    { name: 'contrato', label: 'contrato', placeholder: 'contrato', typeElem: 'input', type: 'text' },
    { name: 'lote', label: 'lote', placeholder: 'lote', typeElem: 'input', type: 'text' },
    { name: 'barrio', label: 'Barrio', placeholder: 'Barrio', typeElem: 'input', type: 'text' },
    { name: 'distrito', label: 'Distrito', placeholder: 'Distrito', typeElem: 'input', type: 'text' },
    { name: 'nombreVia', label: 'Nombre de vía', placeholder: 'Nombre de vía', typeElem: 'input', type: 'text' },
    { name: 'numeroVia', label: 'Número de vía', placeholder: 'Número de vía', typeElem: 'input', type: 'text' },
    { name: 'claseFuncionGIS', label: 'Clase Función GIS', placeholder: 'Clase Función GIS', typeElem: 'input', type: 'text' },
    { name: 'jerarquiaClasificacion', label: 'Jerarquía Clasificación', placeholder: 'Jerarquía Clasificación', typeElem: 'input', type: 'text' },
    { name: 'estado', label: 'Estado', placeholder: 'Estado', typeElem: 'input', type: 'text' },
  ];

  const handleAplicarFiltros = (filtros: DatosFiltrosType) => {
    console.log('Filtros aplicados:', filtros);
    setFiltros(filtros);
    fetchData(0, filtros);
    setMostrarFiltros(false);
  };

  const handleMostrarFiltros = () => {
    setMostrarFiltros(!mostrarFiltros);
  };
  /* END Filtros*/

  // Llamada a la API para recibir los datos
  const fetchData = async (offset: number, filters?: DatosFiltrosType) => {
    try {
      let url = `${urlGetDatos}?limit=${totalElemLimit}&offset=${offset}`;

      if (filters) {
        const filtrosQueryString = Object.entries(filters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');

        // Agrupa los filtros en un campo 'filtros'
        url += `&filtros=${encodeURIComponent(filtrosQueryString)}`;
      }

      const response: AxiosResponse = await getData(url, navigate, token);
      if (response && response.status === 200) {
        const newData = response.data.inventario;
        setInventario(newData);

        // Guardamos el inventario en localStorage
        localStorage.setItem('inventario', JSON.stringify(newData));

        // Guardamos el total elementos
        const total = response.data.total;
        localStorage.setItem('totalRows', response.data.total);
        setTotalRows(total);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  useEffect(() => {
    // fetchData(0); // Cargar los elementos al iniciar la página
  }, []);

  /* useEffect(() => {
    // Si la navegación viene directa desde la home, vaciamos los elementos cargados en memoria
    if (location.state && (location.state as { from?: string } | undefined)?.from === 'Home') {
      localStorage.removeItem('inventario');
      setInventario([]);
    }
  }, [location]); */

  return (
    <div className="inventario-tab">

      <div className="header-container">
        <h3>Listado</h3>

        <div>
          <Button color="white" onClick={() => navigate(`/fuentes-ornamentales/inventario/crear`, { state: { from: 'Inventario' } } )}>
            <img src={AddIcon} alt="Añadir" />
            Añadir
          </Button>

          <Button color="white" onClick={() => navigate(`/comunicaciones`, { state: { from: 'Inventario' } } )}>
            <img src={CommunicationsIcon} alt="Comunicaciones" />
            Comunicaciones
          </Button>

          <Button color="white" onClick={handleMostrarFiltros}>
            <img src={FilterIcon} alt="Filtrar por" />
            Filtrar por
          </Button>

          <Input placeholder="Buscar" iconSrc={SearchIcon} onChange={handleSearch} />

        </div>
      </div>

      <div className="content-container">

        {(!inventario || inventario.length == 0) && !Object.values(filtros).some(val => val !== '') && (
        <div className="no-inventario">
          <p>Para visualizar información primero debe filtrar</p>
          <Button color="primary" onClick={handleMostrarFiltros}>Abrir panel de filtros</Button>
        </div>
        )}

        {(filteredInventario && filteredInventario.length > 0) && (
          <DataTable
            columns={columns}
            data={filteredInventario}
            pagination
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            responsive
            paginationPerPage={elemPerPage}
            paginationTotalRows={totalRows}
            paginationComponent={CustomPagination}
            onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
          />
        )}

        {(inventario && ((searchText !== '') || Object.values(filtros).some(val => val !== '')) && (!filteredInventario || filteredInventario.length == 0)) && (
          <div className="no-data">No hay datos con los filtros aplicados</div>
        )}

        {mostrarFiltros &&
          <FiltersComponent
            datos={filtros}
            camposFormulario={camposFiltros}
            onHide={handleMostrarFiltros}
            onAplicar={handleAplicarFiltros}
          />
        }

        {modalConfig && (
          <AlertModal
            title={modalConfig.title}
            description={modalConfig.description}
            type={modalConfig.type}
            onSubmit={modalConfig.onSubmit ? modalConfig.onSubmit : handleCloseModal}
            onClose={modalConfig.onClose ? modalConfig.onClose : handleCloseModal}
          />
        )}
      </div>

    </div>
  )
};

export default InventarioTab;