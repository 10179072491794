import React, { useState } from 'react';
import '../../assets/scss/pages/_fuentes-ornamentales.scss';
import Navbar from "../../components/Navbar";
import FuentesOrnamentalesIcon from '../../assets/icons/water-grey.svg';
import ActuacionesTab from './Actuaciones';
import InventarioTab from './Inventario';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const FuentesOrnamentalesPage: React.FC = () => {
  const { tab } = useParams<{ tab: string }>();
  const [tabSeleccionada, setTabSeleccionada] = useState(tab || 'inventario');
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleTabClick = (tab: string) => {
    setTabSeleccionada(tab);
    navigate(`/fuentes-ornamentales/${tab}`);
  };

  return (
    <div className="fuentes-ornamentales-page">
      <Navbar />

      <div className="main-content">

        <div className="title">
          <img src={FuentesOrnamentalesIcon} alt="Fuentes ornamentales" />
          <h2>Fuentes ornamentales</h2>
        </div>

        <div className="tabs-container">
          {/* Botones de las pestañas */}
          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-inventario')) && (
            <button onClick={() => handleTabClick('inventario')} className={tabSeleccionada === 'inventario' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
              Inventario
            </button>
          )}

          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-actuaciones')) && (
            <button onClick={() => handleTabClick('actuaciones')} className={tabSeleccionada === 'actuaciones' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
              Actuaciones
            </button>
          )}
          {/*
          <button onClick={() => handleTabClick('piezas-recambio')} className={tabSeleccionada === 'piezas-recambio' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
            Piezas de recambio
          </button>
          <button onClick={() => handleTabClick('dispositivos-medida')} className={tabSeleccionada === 'dispositivos-medida' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
            Dispositivos de medida
          </button>
          <button onClick={() => handleTabClick('horarios')} className={tabSeleccionada === 'horarios' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
            Horarios
          </button>
          */}

        </div>

        <div className="tabs-content">
          {/* Contenido de la pestaña seleccionada */}
          {(tab === 'inventario') && (user && user.permisos.some((permiso: any) => permiso.nombre === 'view-inventario')) && (
            <InventarioTab />
          )}
          {(tab === 'actuaciones') && (user && user.permisos.some((permiso: any) => permiso.nombre === 'view-actuaciones')) && (
            <ActuacionesTab />
          )}
          {tab === 'piezas-recambio' && <></>}
          {tab === 'dispositivos-medida' && <></>}
          {tab === 'horarios' && <></>}

          {(user && !user.permisos.some((permiso: any) => permiso.nombre === 'view-inventario') &&
          !user.permisos.some((permiso: any) => permiso.nombre === 'view-actuaciones')) && (
            <div style={{ textAlign: 'center' }}>No tienes los permisos necesarios para ver este módulo</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FuentesOrnamentalesPage;