import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import Button from '../../components/Button';
import '../../assets/scss/components/_permissions-component.scss';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useAuth } from '../../context/AuthContext';
import { getData } from '../../API/index';
import { Permiso } from "../../API/interfaces";

interface PermissionsComponentProps {
  permisosSeleccionados: string[]; // IDs de los permisos seleccionados
  onChangeCheckbox?: (value: string, isChecked: boolean) => void;
}

const PermissionsComponent: React.FC<PermissionsComponentProps> = ({ permisosSeleccionados, onChangeCheckbox }) => {  const navigate = useNavigate();
  const urlGetDatos = '/api/permisos';
  const { token } = useAuth();
  const [permissions, setpermissions] = useState<Permiso[]>([]);
  const [checkboxes, setCheckboxes] = useState<Record<string, boolean>>({});

  const handleCheckboxChange = (id: string) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [id]: !prevCheckboxes[id],
    }));

    if (checkboxes && onChangeCheckbox) {
      onChangeCheckbox(id, !checkboxes[id]);
    }
  };

  const handleToggleAll = () => {
    setCheckboxes((prevCheckboxes) => {
      const isAllChecked = Object.values(checkboxes).length > 0 && Object.values(prevCheckboxes).every((isChecked) => isChecked);

      const updatedCheckboxes: Record<string, boolean> = {};

      permissions.forEach(({ id }) => {
        updatedCheckboxes[id] = !isAllChecked;
      });

      return updatedCheckboxes;
    });
  };

  const areAllChecked = Object.values(checkboxes).length > 0 && Object.values(checkboxes).every((isChecked) => isChecked);

  const buttonText = areAllChecked ? 'Deseleccionar todo' : 'Seleccionar todo';

  const groupedData = permissions.reduce((acc, permission) => {
    const { seccion, subseccion } = permission;
    if (!acc[seccion]) {
      acc[seccion] = {};
    }
    if (!acc[seccion][subseccion]) {
      acc[seccion][subseccion] = {};
    }
    acc[seccion][subseccion][permission.id] = checkboxes[permission.id] || false;
    return acc;
  }, {} as Record<string, Record<string, Record<string, boolean>>>);

  // Llamada a la API para recibir los datos
  const fetchData = async () => {
    try {
      const response: AxiosResponse = await getData(`${urlGetDatos}`, navigate, token);
      if (response && response.status === 200) {
        const transformedData = response.data.permisos;
        setpermissions(transformedData);

        // Inicializar checkboxes con permisosSeleccionados
        setCheckboxesSelected();
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  const setCheckboxesSelected = () => {
    const initialCheckboxes: Record<string, boolean> = {};
    if(permisosSeleccionados) {
      permisosSeleccionados.forEach((id: string) => {
        initialCheckboxes[id] = true;
      });
      setCheckboxes(initialCheckboxes);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCheckboxesSelected();
  }, [permisosSeleccionados]);

  return (
    <div className="permissions-container">
      <div>
        <h2>Accesos y permisos</h2>

        <Button color="white" onClick={handleToggleAll}>
          {buttonText}
        </Button>
      </div>
      <div className="elements-container">

        <div className="columns-container">
          <p>Sección</p>
          <p>Subsección</p>
          <p>Funciones</p>
        </div>

        {Object.entries(groupedData).map(([seccion, subseccions]) => (
          <div key={seccion}>
            <p>{seccion.toUpperCase()}</p>
            <div>
              {Object.entries(subseccions).map(([subseccionKey, items]) => (
                <div key={subseccionKey}>
                  <h3>{subseccionKey}</h3>
                  {Object.entries(items).map(([id, isChecked]) => (
                    <div key={id}>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        value={permissions.find(permission => String(permission.id) === id)?.id || ''}
                        onChange={() => handleCheckboxChange(id)}
                      />
                      <label>
                        {permissions.find(permission => String(permission.id) === id)?.label || ''}
                      </label>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermissionsComponent;