import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import CreatAndEditGeneralPage, { CampoElemento, DatosType } from "../../../CrearEditarGeneral";
import DefaultAvatarIcon from '../../../../assets/icons/user.svg';
import { AxiosResponse } from 'axios';
import { getData, postData, putData, deleteData } from '../../../../API/index';
import { saveUser, useAuth } from '../../../../context/AuthContext';
import { Role } from "../../../../API/interfaces";

function CrearEditarUsuariosPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: string } | undefined)?.from || 'atrás';
  const { id } = useParams();
  const [datos, setDatos] = useState<DatosType>({});
  const urlDatos = '/api/usuarios';
  const { user, setUser, token } = useAuth();
  const [rolesOptions, setRolesOptions] = useState([]);

  useEffect(() => {
    // Realizar la llamada a la API para obtener las opciones de roles
    fetchRolesOptions();
  }, []);

  const fetchRolesOptions = async () => {
    try {
      const rolesResponse = await getData('/api/roles', navigate, token);
      if (rolesResponse && rolesResponse.status === 200) {
        const rolesData = rolesResponse.data.roles;
        setRolesOptions(rolesData);
      }
    } catch (error) {
      console.error('Error al obtener opciones de roles desde la API', error);
    }
  };

  const camposFormulario: CampoElemento[] = [
    { id: 'name', label: 'Nombre', flex: '4', elementos: [
      { name: 'name', placeholder: 'Nombre', flex: '4', typeElem: 'input', type: 'text' },
    ]},
    { id: 'email', label: 'Correo', flex: '4' , elementos: [
      { name: 'email', placeholder: 'Correo', flex: '4', typeElem: 'input', type: 'email' },
    ]},
    { id: 'password', label: 'Contraseña', flex: '4' , elementos: [
      { name: 'password', placeholder: 'Contraseña', flex: '4', typeElem: 'input', type: 'password' },
    ]},
    { id: 'foto', label: 'Imagen', flex: '4' , elementos: [
      { name: 'foto', placeholder: 'Imagen', flex: '4', typeElem: 'image', type:'foto', src: '', defaultSrc: DefaultAvatarIcon },
    ]},
    { id: 'id_rol', label: 'Rol', flex: '4', elementos: [
      { name: 'id_rol', placeholder: 'Rol', flex: '4', typeElem: 'select', options: rolesOptions.map((role: Role) => ({
        text: role.label,
        value: role.id.toString(),
      })) }
    ]},
  ];

  useEffect(() => {
    if (id) {
      // Llamamos a la api para coger el usuario a editar
      fetchData(Number(id));
    }
  }, [id]);

  // Llamada a la API para recibir los datos
  const fetchData = async (id: number) => {
    try {
      const response: AxiosResponse = await getData(`${urlDatos}/${id}`, navigate, token);
      if (response && response.status === 200) {
        /*const datos = {
          label: response.data.label,
          // Reformatear los permisos para que sea un array de IDs
          permisos: response.data.permisos.map((permiso: any) => permiso.id),
        };*/
        // Establecer los datos en el estado
        setDatos(response.data);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  const handleSaveElem = async (formData?: DatosType): Promise<AxiosResponse<any, any> | null> => {    // Lógica para guardar los datos
    let response: AxiosResponse;

    if(formData) {
      if (id) { // Si hay un ID, estás editando
        response = await putData(`${urlDatos}/${id}`, formData, navigate, token);

        // Si estamos editando nuestro usuario (con el que estamos en navegando), actualizamos el usuario en la sesión
        if(id === user.id) {
          await saveUser(setUser, response.data);
        }
      } else { // Si no hay un ID, estás creando
        response = await postData(`${urlDatos}`, formData, token, navigate);
      }

      return response;
    } else {
      return Promise.resolve(null as unknown as AxiosResponse<any, any>); // Devuelve null
    }
  };

  const handleDeleteElem = async(): Promise<AxiosResponse<any, any>> => {
    // Lógica para borrar los datos
    if (id) {
      return await deleteData(`${urlDatos}/${id}`, navigate, token);
    }
    return Promise.resolve(null as unknown as AxiosResponse<any, any>); // Devuelve null
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <CreatAndEditGeneralPage
      title={id ? 'Editar Usuario' : 'Nuevo Usuario'}
      elementsType='usuario'
      datos={datos}
      camposFormulario={camposFormulario}
      onSave={handleSaveElem}
      onCancel={handleCancel}
      onDelete={handleDeleteElem}
    />

  );
}

export default CrearEditarUsuariosPage;