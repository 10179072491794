import React, { useState, useMemo, useEffect } from 'react';
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import FiltersComponent from "../../../components/Filters";
import AddIcon from '../../../assets/icons/plus-circle.svg';
import CommunicationsIcon from '../../../assets/icons/annotation-dots.svg';
import FilterIcon from '../../../assets/icons/filter-lines.svg';
import SearchIcon from '../../../assets/icons/search.svg';
import DataTable, { TableColumn } from 'react-data-table-component';
import CustomPagination from "../../../components/Pagination";
import MaterialIconMenu, { MaterialIconMenuProps } from "../../../components/MaterialIconMenu";
import { useNavigate, NavigateFunction, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { getData, postData } from '../../../API/index';
import { AxiosResponse } from "axios";
import { AlertModalType, getButtonColor } from "../../../shared/types";
import { Actuacion } from "../../../API/interfaces";
import AlertModal from "../../../components/AlertModal/index";

const addSelectedToRow = (row: Actuacion): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Actuacion) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const ActuacionesTab = (): JSX.Element => {

  const elemPerPage = 15;
  const totalElemLimit = 1000;

  const urlGetDatos = '/api/iho/actuaciones';
  const [actuaciones, setActuaciones] = useState<Actuacion[]>(() => {
    const storedData = localStorage.getItem('actuaciones');
    return storedData ? JSON.parse(storedData) : [];
  });
  const [selectedRows, setSelectedRows] = useState<Actuacion[]>();
  const [searchText, setSearchText] = useState('');
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [modalConfig, setModalConfig] = useState<{ title: string; type: AlertModalType, description?: string, onSubmit?: () => void, onClose?: () => void } | null>(null);
  const location = useLocation();
  const navigate = useNavigate() as NavigateFunction;
  const { token } = useAuth();
  const urlEnviarMINTDatos = '/api/carga/iho/actuaciones/BBDD';

  const handleShowMap = (actuacion: Actuacion) => {
    let elements: Actuacion[] = [ actuacion ];
    if(selectedRows && selectedRows.length > 0) {
      elements = selectedRows;
    }
    const state = { elements, tipo: 'Actuación', from: 'ActuaciónListado' };
    window.history.pushState(state, '', ''); // Hay que hacer este push state porque sino al ir al mapa desde el listado
                                             // y dar a atrás, sale que venimos desde la home, ya que es lo último en el historial
    navigate('/mapa-fuentes', { state });
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleSentToMINT = async (row: Actuacion) => {
    let elements: string[] = [ String(row.id) ];
    if(selectedRows && selectedRows.length > 0) {
      elements = elements.concat(selectedRows.flatMap((selectedRow) => String(selectedRow.id)));
    }

    const formData = {
      ids: elements
    };

    const response = await postData(`${urlEnviarMINTDatos}`, formData, token, navigate);
    if(response.status === 200) {
      const config = {
        title: 'Elemento cargado en gestión de envíos',
        description: 'El elemento se ha puesto en cargas de envíos',
        type: 'ok' as AlertModalType,
        onSubmit: handleCloseModal,
        onClose: handleCloseModal
      };
      setModalConfig(config);
    }
  };

  const handleCloseModal = () => {
    setModalConfig(null);
  };

  // Filtrar actuaciones según el texto de búsqueda
  const filteredActuaciones = useMemo(() => {
    return actuaciones.filter(actuacion =>
      Object.values(actuacion).some(value =>
        value && value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [actuaciones, searchText]);


  const columns: TableColumn<Actuacion>[] = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row.id,
        sortable: true
      },
      {
        name: 'Id Actuación',
        selector: (row) => row.idActuacion,
        sortable: true
      },
      {
        name: 'Barrio',
        selector: (row) => row.barrio,
        sortable: true
      },
      {
        name: 'Distrito',
        selector: (row) => row.distrito,
        sortable: true
      },
      {
        name: 'Jerarquía Clasificación',
        selector: (row) => row.jerarquiaClasificacion,
        sortable: true
      },
      {
        name: 'Nombre vía',
        selector: (row) => row.nombreVia,
        sortable: true
      },
      {
        name: 'Número vía',
        selector: (row) => row.numeroVia,
        sortable: true
      },
      {
        name: 'Estado General',
        selector: (row) => row.estado_general.label, // Selector que devuelve un tipo primitivo
        sortable: true,
        cell: (row) => (
          <Button className="estado-button" color={getButtonColor(row.estado_general.nombre)}>
            {row.estado_general.label}
          </Button>
        ),
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Actuacion> = {
            row,
            size: 'medium',
            rows: selectedRows && selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              {label: 'Ver en mapa', onClick: row => handleShowMap(row)},
              {
                label: 'Editar',
                onClick: row => navigate(`/fuentes-ornamentales/actuaciones/editar/${row.id}`, { state: { from: 'Actuaciones' } } ),
                onlyApplyToOne: true,
              },
              { label: `Enviar a MiNT`, onClick: row => handleSentToMINT(row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  /* Filtros */
  type DatosFiltrosType = {
    id: string,
    idActuacion: string,
    idActuacionExterno: string,
    idInventario: string,
    contrato: string,
    lote: string,
    barrio: string,
    distrito: string,
    nombreVia: string,
    numeroVia: string,
    claseFuncionGIS: string,
    jerarquiaClasificacion: string,
    estado: string,
  };

  const [filtros, setFiltros] = useState<DatosFiltrosType>({
    id: '',
    idActuacion: '',
    idActuacionExterno: '',
    idInventario: '',
    contrato: '',
    lote: '',
    barrio: '',
    distrito: '',
    nombreVia: '',
    numeroVia: '',
    claseFuncionGIS: '',
    jerarquiaClasificacion: '',
    estado: '',
  });

  const camposFiltros = [
    { name: 'id', label: 'ID', placeholder: 'ID', typeElem: 'input', type: 'text' },
    { name: 'idActuacion', label: 'idActuacion', placeholder: 'idActuacion', typeElem: 'input', type: 'text' },
    { name: 'idActuacionExterno', label: 'idActuacionExterno', placeholder: 'idActuacionExterno', typeElem: 'input', type: 'text' },
    { name: 'idInventario', label: 'idInventario', placeholder: 'idInventario', typeElem: 'input', type: 'text' },
    { name: 'contrato', label: 'contrato', placeholder: 'contrato', typeElem: 'input', type: 'text' },
    { name: 'lote', label: 'lote', placeholder: 'lote', typeElem: 'input', type: 'text' },
    { name: 'barrio', label: 'Barrio', placeholder: 'Barrio', typeElem: 'input', type: 'text' },
    { name: 'distrito', label: 'Distrito', placeholder: 'Distrito', typeElem: 'input', type: 'text' },
    { name: 'nombreVia', label: 'Nombre de vía', placeholder: 'Nombre de vía', typeElem: 'input', type: 'text' },
    { name: 'numeroVia', label: 'Número de vía', placeholder: 'Número de vía', typeElem: 'input', type: 'text' },
    { name: 'claseFuncionGIS', label: 'Clase Función GIS', placeholder: 'Clase Función GIS', typeElem: 'input', type: 'text' },
    { name: 'jerarquiaClasificacion', label: 'Jerarquía Clasificación', placeholder: 'Jerarquía Clasificación', typeElem: 'input', type: 'text' },
    { name: 'estado', label: 'Estado', placeholder: 'Estado', typeElem: 'input', type: 'text' },
  ];

  const handleAplicarFiltros = (filtros: DatosFiltrosType) => {
    console.log('Filtros aplicados:', filtros);
    setFiltros(filtros);
    fetchData(0, filtros);
    setMostrarFiltros(false);
  };

  const handleMostrarFiltros = () => {
    setMostrarFiltros(!mostrarFiltros);
  };
  /* END Filtros*/

  // Llamada a la API para recibir los datos
  const fetchData = async (offset: number, filters?: DatosFiltrosType) => {
    try {
      let url = `${urlGetDatos}?limit=${totalElemLimit}&offset=${offset}`;

      if (filters) {
        const filtrosQueryString = Object.entries(filters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');

        // Agrupa los filtros en un campo 'filtros'
        url += `&filtros=${encodeURIComponent(filtrosQueryString)}`;
      }

      const response: AxiosResponse = await getData(url, navigate, token);
      if (response && response.status === 200) {
        const newData = response.data.actuaciones;
        setActuaciones(newData);

        // Guardamos las actuaciones en localStorage
        localStorage.setItem('actuaciones', JSON.stringify(newData));
        setTotalRows(response.data.total);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  useEffect(() => {
    // fetchData(0); // Cargar los elementos al iniciar la página
  }, []);

  return (
    <div className="actuaciones-tab">

      <div className="header-container">
        <h3>Listado</h3>

        <div>
          <Button color="white" onClick={() => navigate(`/fuentes-ornamentales/actuaciones/crear`, { state: { from: 'Actuaciones' } } )}>
            <img src={AddIcon} alt="Añadir" />
            Añadir
          </Button>

          <Button color="white" onClick={() => navigate(`/comunicaciones`, { state: { from: 'Actuaciones' } } )}>
            <img src={CommunicationsIcon} alt="Comunicaciones" />
            Comunicaciones
          </Button>

          <Button color="white" onClick={handleMostrarFiltros}>
            <img src={FilterIcon} alt="Filtrar por" />
            Filtrar por
          </Button>

          <Input placeholder="Buscar" iconSrc={SearchIcon} onChange={handleSearch} />

        </div>
      </div>

      <div className="content-container">

        {(!actuaciones || actuaciones.length == 0) && !Object.values(filtros).some(val => val !== '') && (
        <div className="no-actuaciones">
          <p>Para visualizar información primero debe filtrar</p>
          <Button color="primary" onClick={handleMostrarFiltros}>Abrir panel de filtros</Button>
        </div>
        )}

        {(filteredActuaciones && filteredActuaciones.length > 0) && (
          <DataTable
            columns={columns}
            data={filteredActuaciones}
            pagination
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            responsive
            paginationPerPage={elemPerPage}
            paginationTotalRows={totalRows}
            paginationComponent={CustomPagination}
            onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
          />
        )}

        {(actuaciones && ((searchText !== '') || Object.values(filtros).some(val => val !== '')) && (!filteredActuaciones || filteredActuaciones.length == 0)) && (
          <div className="no-data">No hay datos con los filtros aplicados</div>
        )}
        
        {mostrarFiltros &&
          <FiltersComponent
            datos={filtros}
            camposFormulario={camposFiltros}
            onHide={handleMostrarFiltros}
            onAplicar={handleAplicarFiltros}
          />
        }

        {modalConfig && (
          <AlertModal
            title={modalConfig.title}
            description={modalConfig.description}
            type={modalConfig.type}
            onSubmit={modalConfig.onSubmit ? modalConfig.onSubmit : handleCloseModal}
            onClose={modalConfig.onClose ? modalConfig.onClose : handleCloseModal}
          />
        )}
      </div>

    </div>
  )
};

export default ActuacionesTab;