import React, { useState, useEffect } from 'react';
// import { MarkerData } from "../Mapa/index";
import CloseIcon from '../../../assets/icons/close.svg';
import AddIcon from '../../../assets/icons/plus-circle.svg';
import Button from "../../../components/Button/index";
import {Color, getButtonColor} from '../../../shared/types';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedMarker: any | null;
  tipo: string;
  onNext: () => void;
  onPrev: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, selectedMarker, tipo, onNext, onPrev }) => {

  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0);

  const modalStyles: React.CSSProperties = {
    display: isOpen ? 'block' : 'none',
  };

  const overlayStyles: React.CSSProperties = {
    display: isOpen ? 'block' : 'none',
  };

  const excludedKeys = ['id', 'json', 'id_modulo', 'estado_general', 'id_estado_general'];

  useEffect(() => {
    setCurrentMarkerIndex(0); // Reiniciar el índice al abrir la modal
  }, [isOpen]);

  const handleNext = () => {
    if (selectedMarker && currentMarkerIndex < selectedMarker.length - 1) {
      setCurrentMarkerIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedMarker && currentMarkerIndex > 0) {
      setCurrentMarkerIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <>
      <div className="modal-mapa" style={modalStyles}>
        {selectedMarker && selectedMarker[currentMarkerIndex] && (
          <>
            <div className="id-container">
              <div>
                <p>{tipo}</p>
                <p>ID {selectedMarker[currentMarkerIndex].id}</p>
              </div>
              <img src={CloseIcon} onClick={onClose} alt="Close Icon" />
            </div>

            <div className="datos-container">
              <Button
                className="estado-button"
                color={getButtonColor(selectedMarker[currentMarkerIndex].estado_general.nombre)}
              >
                {selectedMarker[currentMarkerIndex].estado_general.label}
              </Button>

              {Object.keys(selectedMarker[currentMarkerIndex]).map((key) => {
                if (!excludedKeys.includes(key)) {
                  if (selectedMarker[currentMarkerIndex][key] !== null) {
                    return (
                      <p key={key}>
                        <strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong>{' '}
                        {selectedMarker[currentMarkerIndex][key]}
                      </p>
                    );
                  }
                }
              })}
            </div>

            {selectedMarker[currentMarkerIndex].actuaciones && (
              <div className="actuaciones-container">
                <p className="name">Actuaciones</p>
                {selectedMarker[currentMarkerIndex].actuaciones.map((actuacion: any) => (
                  <div key={actuacion.idActuacion}>
                    <p>
                      <strong>{actuacion.idActuacion}</strong>
                      <br />
                      {actuacion.fecha}
                    </p>
                    <Button
                      className="estado-button"
                      color={getButtonColor(actuacion.estado_general.nombre)}
                    >
                      {actuacion.estado_general.label}
                    </Button>
                  </div>
                ))}

                <div className="buttons-container">
                  <Button color="transparent">Ver más</Button>
                  <Button color="white">
                    <img src={AddIcon} alt="Añadir actuación" />
                    Añadir actuación
                  </Button>
                </div>
              </div>
            )}

            {selectedMarker.length > 1 && (
              <div className="navigation-buttons">
                <Button
                  color="transparent"
                  onClick={handlePrev}
                  isDisabled={currentMarkerIndex === 0}
                >
                  Anterior
                </Button>
                <Button
                  color="transparent"
                  onClick={handleNext}
                  isDisabled={currentMarkerIndex === selectedMarker.length - 1}
                >
                  Siguiente
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <div className="modal-overlay" style={overlayStyles} onClick={onClose} />
    </>
  );
};

export default Modal;
