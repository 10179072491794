import React, { useState } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Checkbox from "../../components/Checkbox/index";
import Select from "../../components/Select/index";

import logo from '../../assets/logos/iris-black.svg';
import '../../assets/scss/pages/_login.scss';

function TestPage() {
  const [name, setName] = useState('');

  const handleNameChange = (newName: string) => {
    setName(newName);
  };

  const options = [
    {
      text: 'Vehículo',
      value: 'vehiculo',
    },
    {
      text: 'Maquinaria',
      value: 'maquinaria',
    },
  ];


  return (
    <div className="login-container">
      <div>
        <div style={{ gap: '8px', display: 'flex', flexDirection: 'column', maxWidth: '20%', padding: '30px' }}>
          <img src={logo} alt="Home" style={{ width: '100px' }}/>
          <h1>h1</h1>
          <h2>h2</h2>

          <h2>Inputs</h2>
          <Input label="Email" value={name} placeholder="Enter your email" onChange={handleNameChange} />
          <Input label="Contraseña" value={name} placeholder="••••••••" type="password" onChange={handleNameChange} />

          <h2>Select</h2>
          <Select
            label="Tipo de vehículo"
            options={options}
            value="vehiculo"
            onChange={e => console.log(e.target.value) }
          />

          <h2>Buttons</h2>
          <Button color="primary">Ingresar</Button>
          <Button color="outline">Ingresar</Button>
          <Button color="success">Ejecutado</Button>
          <Button color="warning">Programado</Button>
          <Button color="danger">Cancelado</Button>
          <Button color="disabled">Ingresar</Button>
          <Button color="white">Ingresar</Button>
          <Button color="transparent">Olvidé mi contraseña</Button>

          <h2>Checkboxes</h2>
          <Checkbox
            onChecked={ checked => {
              console.log(checked);
            }}
            label="Recordarme"
          />
        </div>
      </div>

      <div className="img"></div>
    </div>
  );
}

export default TestPage;